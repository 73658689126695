<template>
  <div class="history-overview-wrapper">
    <!-- Mobile Tab -->
    <!--    <div v-if="$vuetify.breakpoint.mdAndDown" style="text-align: center">-->
    <!--      <v-btn class="text-center history-tab-mobile" style="margin-right: 5px" :color="selectedTab == 'RUNNING' ? 'primary' : ''" depressed @click="onChangeTab('RUNNING')">-->
    <!--        <withdrawal-running class="mobile-icon" :class="selectedTab == 'RUNNING' ? 'mobile-icon-active' : ''"></withdrawal-running>-->
    <!--        <span>-->
    <!--          <v-badge v-if="selectedTab == 'RUNNING' && turnoverTicket.length > 1" bordered color="error" :content="turnoverTicket.length">-->
    <!--            <span>{{ $t(`label.running`) }}</span>-->
    <!--          </v-badge>-->
    <!--          <span v-else>{{ $t(`label.running`) }}</span>-->
    <!--        </span>-->
    <!--      </v-btn>-->
    <!--      <v-btn class="text-center history-tab-mobile" style="margin-left: 5px" :color="selectedTab == 'COMPLETED' ? 'primary' : ''" depressed @click="onChangeTab('COMPLETED')">-->
    <!--        <withdrawal-completed class="mobile-icon" :class="selectedTab == 'COMPLETED' ? 'mobile-icon-active' : ''"></withdrawal-completed>-->
    <!--        <span>{{ $t(`label.completed`) }}</span>-->
    <!--      </v-btn>-->
    <!--    </div>-->
    <!-- End Mobile Tab -->

    <div class="turnover-message hidden-md-and-down">
      {{ $t(`message.turnoverMessage`) }}
    </div>
    <v-row class="filter-card-wrapper hidden-md-and-up no-gutters">
      <v-col cols="12" class="d-flex" style="gap: 1rem;">
        <div>
          <v-card text :ripple="false" depressed class="game-menu-content" :class="[this.selectedTab == 'RUNNING' ? 'selected' : '']" @click="onChangeTab('RUNNING')">
            <div class="toStatus-card-image mx-auto" :style="`--src:url('/static/image/turnover/toRunning.svg')`" :class="[this.selectedTab == 'RUNNING' ? 'selected' : '']">
              <img :url="`/static/image/turnover/toRunning.png`" class="" />
            </div>
            <div class="game-menu-title">{{ $t(`label.running`) }}</div>
          </v-card>
        </div>
        <div>
          <v-card text :ripple="false" depressed class="game-menu-content" :class="[this.selectedTab == 'COMPLETED' ? 'selected' : '']" @click="onChangeTab('COMPLETED')">
            <div class="toStatus-card-image mx-auto" :style="`--src:url('/static/image/turnover/toCompleted.svg')`" :class="[this.selectedTab == 'COMPLETED' ? 'selected' : '']">
              <img :url="`/static/image/turnover/toCompleted.png`" class="" />
            </div>
            <div class="game-menu-title">{{ $t(`label.completed`) }}</div>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-card elevation="0" class="desktop_card">
      <div class="turnover-message hidden-md-and-up mobile-to-message">
        {{ $t(`message.turnoverMessage`) }}
      </div>
      <v-card-title class="justify-space-between" v-if="$vuetify.breakpoint.mdAndUp">
        <label class="--v-primary-base text-left">{{ $t(`label.turnoverHistory`) }}</label>
      </v-card-title>
      <!-- Desktop Tab -->
      <v-row v-if="$vuetify.breakpoint.lgAndUp" no-gutters justify="end" class="px-10">
        <v-col class="text-right" cols="12">
          <v-btn class="history-tab" :color="selectedTab == 'RUNNING' ? 'primary' : ''" depressed @click="onChangeTab('RUNNING')">
            <withdrawal-running class="mobile-icon" :class="selectedTab == 'RUNNING' ? 'mobile-icon-active' : ''">cached</withdrawal-running>
            <span>
              <v-badge v-if="selectedTab == 'RUNNING' && turnoverTicket.length > 1" bordered color="error" :content="turnoverTicket.length">
                <span>{{ $t(`label.running`) }}</span>
              </v-badge>
              <span v-else>{{ $t(`label.running`) }}</span>
            </span>
          </v-btn>
          <v-btn class="history-tab" :color="selectedTab == 'COMPLETED' ? 'primary' : ''" depressed @click="onChangeTab('COMPLETED')">
            <withdrawal-completed class="mobile-icon" :class="selectedTab == 'COMPLETED' ? 'mobile-icon-active' : ''"></withdrawal-completed>
            <span>{{ $t(`label.completed`) }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <!-- End Desktop Tab -->
      <v-card :class="$vuetify.breakpoint.lgAndUp ? 'history-card' : 'history-card-mobile'" elevation="0">
        <!-- Desktop -->
        <v-row no-gutters v-if="$vuetify.breakpoint.mdAndUp">
          <v-col cols="12" v-if="turnoverTicket.length <= 0">
            <p class="text-center my-4">No Pending Ticket Found !</p>
          </v-col>
          <!-- Running -->
          <v-col cols="12" class="all-wallet-wrap px-5" v-else>
            <div id="turnover-16065131" :class="['bonus-wallet-wrap', n.is_served ? 'served' : 'progressing']" v-for="(n, k) in turnoverTicket" :title="n.remark">
              <div class="bonus-wallet">
                <div class="title" ui-key="title">{{ n.remark }}</div>
                <div class="card-detail">
                  <div class="card-date end_time">
                    {{ $t(`label.event_end`) }}
                    {{ new Date(new Date().getFullYear(), 11, 31).toLocaleDateString() }}
                  </div>
                  <div class="detail-btn">
                    <a>
                      <span @click="setDetailsDialog(n)">{{ $t(`label.detail`) }}</span>
                    </a>
                  </div>
                </div>
                <div class="wallet-inner">
                  <div class="wallet-inner-right" data-messagetype="1" data-id="16065131">
                    <div class="discount">
                      <div class="currency">৳</div>
                      <div class="amount-current">{{ n.current_progress | floatParser }}</div>
                      <div class="symbol">/</div>
                      <div class="currency-total">৳</div>
                      <div class="amount-total">{{ n.total_rollover | floatParser }}</div>
                    </div>
                    <div class="discount02">
                      <div class="turnover">
                        <span>{{ $t(`label.turnover`) }}</span>
                        ：X
                        <span>{{ n.multiplier }}</span>
                      </div>
                    </div>
                    <div class="progress-group">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" :style="'width:' + getProgressBar(n) + '%'"></div>
                      </div>
                      <div style="margin-left: 10px; color: #f9d40a">{{ getProgressBar(n) + '%' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <!-- End Overview -->
        </v-row>
        <!-- End Desktop -->

        <!-- Mobile -->
        <v-row no-gutters v-if="$vuetify.breakpoint.mdAndDown">
          <v-col cols="12" v-if="turnoverTicket.length <= 0">
            <p class="text-center my-4">No Pending Ticket Found !</p>
          </v-col>
          <!-- Running -->
          <v-col cols="12" class="all-wallet-wrap px-2" v-else>
            <div id="turnover-16065131" :class="['bonus-wallet-wrap', n.is_served ? 'served' : 'progressing']" v-for="(n, k) in turnoverTicket" :title="n.remark">
              <div class="bonus-wallet">
                <div class="title" ui-key="title">{{ n.remark }}</div>
                <div class="card-detail">
                  <div class="card-date end_time">
                    {{ $t(`label.event_end`) }}
                    {{ new Date(new Date().getFullYear(), 11, 31).toLocaleDateString() }}
                  </div>
                  <div class="detail-btn">
                    <a>
                      <span @click="setDetailsDialog(n)">{{ $t(`label.detail`) }}</span>
                    </a>
                  </div>
                </div>
                <div class="wallet-inner">
                  <div class="wallet-inner-right" data-messagetype="1" data-id="16065131">
                    <div class="discount">
                      <div class="currency-mobile">৳</div>
                      <div class="amount-current-mobile">{{ n.current_progress | floatParser }}</div>
                      <div class="symbol-mobile">/</div>
                      <div class="currency-total-mobile">৳</div>
                      <div class="amount-total-mobile">{{ n.total_rollover | floatParser }}</div>
                    </div>
                    <div class="discount02">
                      <div class="turnover">
                        <span>{{ $t(`label.turnover`) }}</span>
                        ：X
                        <span>{{ n.multiplier }}</span>
                      </div>
                    </div>
                    <div class="progress-group-mobile">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" :style="'width:' + getProgressBar(n) + '%'"></div>
                      </div>
                      <div style="margin-left: 10px; color: #FFCE01">{{ getProgressBar(n) + '%' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <!-- End Overview -->
        </v-row>
        <!-- End Mobile -->
      </v-card>
    </v-card>
    <v-row justify="center">
      <v-dialog v-model="detailDialog" max-width="420">
        <v-card>
          <v-card-title class="subtitle-1 bg-pop white--text">
            {{ detailObj.title }}
          </v-card-title>
          <div class="pop-wrap pop-inner article">
            <table>
              <tbody>
                <tr>
                  <td>
                    <span>{{ $t(`label.transAmount`) }}</span>
                  </td>
                  <td>{{ detailObj.deposit_amount | floatParser }}</td>
                </tr>
                <tr>
                  <td>
                    <span>{{ $t(`label.bonus`) }}</span>
                  </td>
                  <td>{{ detailObj.bonus_amount | floatParser }}</td>
                </tr>
                <tr>
                  <td>
                    <span>{{ $t(`label.to_req`) }}</span>
                  </td>
                  <td>{{ detailObj.to_req | floatParser }}</td>
                </tr>
                <tr>
                  <td>
                    <span>{{ $t(`label.to_completed`) }}</span>
                  </td>
                  <td>{{ detailObj.to_completed | floatParser }}</td>
                </tr>
                <tr>
                  <td>
                    <span>{{ $t(`label.to_ratio`) }}</span>
                  </td>
                  <td>{{ detailObj.to_ratio | floatParser }}%</td>
                </tr>
                <tr>
                  <td>
                    <span>{{ $t(`label.to_created_time`) }}</span>
                  </td>
                  <td>{{ detailObj.to_create_time | longDate }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import withdrawalCompleted from '@/assets/svg/icon_completed.svg'
import withdrawalRunning from '@/assets/svg/icon_running.svg'
import { uiHelper, locale, errorCodeHelper, formValidator, ddlHandler } from '@/util'
import { SHARED } from '@/constants/constants'
import _ from 'lodash'
import { HISTORY_OVERVIEW, HISTORY_DETAIL } from '@/store/history.module'
import { MEMBER_TURNOVER_TICKET } from '@/store/member.module'

export default {
  name: 'playerHistory',
  components: {
    withdrawalCompleted,
    withdrawalRunning
  },
  data: function() {
    return {
      detailObj: {
        title: '',
        deposit_amount: '',
        bonus_amount: '',
        to_req: '',
        to_completed: '',
        to_ratio: '',
        to_create_time: ''
      },
      detailDialog: false,
      dateMenu: false,
      selectMenu: false,
      dates: SHARED.DEFAULT_DATE,
      oneMonthDate: SHARED.DEFAULT_SEVEN_DAYS,
      defaultDate: SHARED.DEFAULT_DATE,
      language: uiHelper.getLanguage(),
      currentCurrency: uiHelper.getCurrency(),
      validator: formValidator,
      shared: SHARED,
      selectedTab: 'RUNNING',
      historyProvider: '',
      historyGameType: 1,
      slotsProviderDdl: ddlHandler.getSlotsProvider(),
      sportsProviderDdl: ddlHandler.getSportsProvider(),
      liveProviderDdl: ddlHandler.getLiveProvider(),
      tableProviderDdl: ddlHandler.getTableProvider(),
      cricketProviderDdl: ddlHandler.getCricketProvider(),
      selectedList: [],
      pagination: {
        currentPage: 1,
        totalPage: 1,
        paginationPageLimit: 10
      },
      historyDetail: {
        data: [
          {
            tip: 0
          }
        ]
      },
      searchCriteria: {
        pageNumber: 1,
        pageSize: 20,
        mobilePageSize: 10
      }
    }
  },
  created() {
    this.getOverviewHistory(this.selectedTab)
    this.getDetailHistory()
  },
  computed: {
    turnoverTicket() {
      return this.$store.state.member.turnoverTicket.data
    }
  },
  watch: {
    turnoverTicket() {
      return this.$store.state.member.turnoverTicket.data
    }
  },
  methods: {
    getProgressBar(obj) {
      let current_progress = parseFloat(obj.current_progress)
      let total_rollover = parseFloat(obj.total_rollover)
      let ratio = 0
      try {
        ratio = (obj.current_progress == 0 && obj.total_rollover == 0) || current_progress >= total_rollover ? 1 : obj.current_progress == 0 ? 0 : obj.current_progress / obj.total_rollover
        return ratio * 100
      } catch (err) {}
    },
    setDetailsDialog(obj) {
      let current_progress = parseFloat(obj.current_progress)
      let total_rollover = parseFloat(obj.total_rollover)
      let ratio = 0
      try {
        ratio = (obj.current_progress == 0 && obj.total_rollover == 0) || current_progress >= total_rollover ? 1 : obj.current_progress == 0 ? 0 : obj.current_progress / obj.total_rollover
        ratio = ratio * 100
      } catch (err) {}
      this.detailObj = {
        title: obj.remark,
        deposit_amount: obj.request_amount,
        bonus_amount: obj.bonus_amount,
        to_req: obj.total_rollover,
        to_completed: obj.current_progress,
        to_create_time: obj.created_at,
        to_ratio: ratio
      }
      this.detailDialog = true
    },
    onChangeTab(type) {
      this.historyList = []
      this.selectedTab = type
      this.getOverviewHistory(this.selectedTab)
    },
    getOverviewHistory(is_running) {
      let filter = {
        is_served: is_running != 'RUNNING' ? 1 : 0
      }
      this.$store.dispatch(`${MEMBER_TURNOVER_TICKET}`, {
        filter
      })
    },
    clearFilter() {
      this.dates = []
      this.onChangeTab(this.selectedTab)
    }
  }
}
</script>

<style lang="scss">

.filter-card-wrapper {
  max-width: 959px;
  white-space: nowrap;
  overflow: auto;
  display: flex;
  align-items: center;
  background-color: var(--v-text-base);
  border-bottom: 1px solid #dadada;
  background-color: #fff;
  flex-wrap: nowrap;
  overflow-x: auto;
  justify-content: flex-start;
  padding: 12px 12px 12px 12px;

  .game-menu-content.v-card {
    min-width: 50px;
    width: 65px;
    height: 60px;
    text-transform: capitalize;
    padding: 10px 0px !important;
    background-color: var(--v-newMobileSecondaryDarker-base);
    color: #ffffff;
  }
  .toStatus-card-image {
    background-color: #ffffff;
    -webkit-mask-image: var(--src);
    mask-image: var(--src);
    -webkit-mask-size: 100%;
    mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    width: 30px;
    height: 30px;
    margin-left: 8px;
    &.selected {
      background-color: #FFFFFF;
    }
  }
  .game-menu-title {
    text-align: center;
    font-size: 12px;
  }

  .game-menu-content {
    &.selected {
      color: #FFFFFF;
      opacity: 1;
      background: var(--v-newMobilePrimary-base);

      .game-menu-image {
        background-color: #000000;
      }
    }
  }
}



.history-card-mobile {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 300px;
  padding-bottom: 60px;
}

.history-card {
  height: 75%;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0px 40px;
  border-radius: 10px !important;
  .date-search {
    width: fit-content;
    margin-right: 0px;
    float: right !important;

    .v-input__slot {
      min-height: 40px !important;
      max-width: 250px;
    }

    .v-input__append-inner {
      margin-top: 8px;
    }
  }
}

.mobile-history-card {
  text-overflow: ellipsis;
  width: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.history-tab {
  width: 150px;
  margin-right: 16px;
  padding: 10px;
  background-color: #fff;
  text-transform: capitalize;
  .mobile-icon {
    margin-right: 5px;
    width: 30px;
    height: 25px;
  }
  .mobile-icon-active {
    fill: #3a3a3a !important;
    path {
      fill: #fff !important;
    }
  }
}

.history-tab-mobile {
  width: fit-content !important;
  min-width: 150px !important;
  padding: 2px;
  background-color: #fff;
  text-transform: capitalize;
  font-size: 14px !important;

  .mobile-icon {
    margin-right: 3px;
    width: 30px;
    height: 30px;
  }

  .mobile-icon-active {
    fill: #3a3a3a !important;

    path {
      fill: #fff !important;
    }
  }
}

.all-wallet-wrap {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  justify-content: space-between;
}

.bonus-wallet-wrap {
  display: block;
  width: 49.4%;
  margin: 0.3125rem 0;
  border-radius: 10px;

  &.progressing {
    background: #3a3a3a;
  }

  &.served {
    background-image: linear-gradient(180deg, #606779 0%, #454957 73%);
  }
}

.bonus-wallet-wrap .bonus-wallet {
  display: block;
  position: relative;
  //height: 9.0625rem;
  padding: 0.625rem;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 -3px 2px rgb(183 183 183 / 10%);
}

.bonus-wallet-wrap .title {
  position: relative;
  overflow: hidden;
  color: #f9d40a;
  font-size: 14px !important;
  font-weight: 500;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0;
}

.bonus-wallet-wrap .wallet-label,
.bonus-wallet-wrap .wallet-label-hot,
.bonus-wallet-wrap .wallet-label-open,
.bonus-wallet-wrap .wallet-label-complete {
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 3.5625rem;
  height: 3.5625rem;
  background: url('/static/image/turnover/wallet-new.svg') no-repeat top center/cover;
}

.bonus-wallet-wrap .wallet-label-hot {
  background: url('/static/image/turnover/wallet-hot.svg') no-repeat top center/cover;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.bonus-wallet-wrap .wallet-inner {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 0.625rem;
  border-radius: 0.25rem;
}

.bonus-wallet-wrap .wallet-inner-left {
  width: 0px;
  height: 5.125rem;
  margin-right: 0.9375rem;
  overflow: hidden;
  border-radius: 0.25rem;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.bonus-wallet-wrap .wallet-inner-right {
  display: block;
  position: relative;
  width: 75%;
}

.bonus-wallet-wrap .card-detail {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
}

.bonus-wallet-wrap .card-date {
  margin: auto 0;
  color: #f8f8f8;
  font-size: 13px;
}

.bonus-wallet-wrap .detail-btn {
  display: flex;
  margin: auto 0.3125rem;
  padding: 0px 12px;
  border: 0.0625rem solid #f8f8f8;
  border-radius: 6px;
}

.bonus-wallet-wrap .detail-btn a {
  color: #f8f8f8;
  font-size: 10px;
  text-decoration: none;
}

.bonus-wallet-wrap .discount {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
}

.bonus-wallet-wrap .discount .currency {
  margin: auto 0;
  padding-right: 0.3125rem;
  font-size: 0.875rem;
  color: #f9d40a;
}

.bonus-wallet-wrap .discount .currency-mobile {
  margin: auto 0;
  padding-right: 0.3125rem;
  font-size: 15px;
  color: #f9d40a;
}

.bonus-wallet-wrap .discount .currency-total {
  margin: auto 0;
  padding-right: 0.3125rem;
  font-size: 0.875rem;
  color: #f8f8f8;
}

.bonus-wallet-wrap .discount .currency-total-mobile {
  margin: auto 0;
  padding-right: 0.3125rem;
  font-size: 15px;
  color: #f8f8f8;
}

.bonus-wallet-wrap .discount .symbol {
  margin: auto 0;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  font-size: 25px;
  color: #f8f8f8;
}

.bonus-wallet-wrap .discount .symbol-mobile {
  margin: auto 0;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  font-size: 15px;
  color: #f8f8f8;
}

.bonus-wallet-wrap .discount .amount-current {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  font-size: 1.5625rem;
  color: #f9d40a;
}

.bonus-wallet-wrap .discount .amount-current-mobile {
  padding-top: 0.3125rem;
  font-size: 15px;
  color: #f9d40a;
}

.bonus-wallet-wrap .discount .amount-total {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  font-size: 1.5625rem;
  color: #f8f8f8;
}

.bonus-wallet-wrap .discount .amount-total-mobile {
  padding-top: 0.3125rem;
  font-size: 15px;
  color: #f8f8f8;
}

.bonus-wallet-wrap .discount02 {
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  margin: 1.25rem 0 0.625rem;
  color: #fff;
  font-size: 0.75rem;
}

.bonus-wallet-wrap .wallet-inner-right .progress-group {
  position: relative;
  width: 93%;
  margin: 0;
  border-bottom: 0 solid;
  display: flex;
  flex-direction: row;
}

.bonus-wallet-wrap .wallet-inner-right .progress-group-mobile {
  position: relative;
  width: 130%;
  margin: 0;
  border-bottom: 0 solid;
  display: flex;
  flex-direction: row;
}

.bonus-wallet-wrap .wallet-inner-right .progress {
  flex: 1 0 80%;
  height: 7px;
  margin-bottom: 0.3125rem;
  margin-top: 8px;
  border-radius: 0.3125rem;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.progress-bar {
  position: relative;
  width: 0;
  height: 100%;
  float: left;
  transition: width 0.6s ease;
  border-radius: 0.3125rem;
  background: #fadc01;
  box-shadow: 0 0 0.1875rem #ffea65;
}

.bonus-wallet-wrap .wallet-inner-right .progress-group > span {
  display: inline-block;
  width: 49%;
  color: #b8b8b8;
  font-size: 0.8125rem;
  line-height: 1.2;
  word-wrap: break-word;
  word-break: break-all;
}

.bonus-wallet-wrap .wallet-inner-right .progress-group > span {
  display: inline-block;
  width: 49%;
  color: #b8b8b8;
  font-size: 0.8125rem;
  line-height: 1.2;
  word-wrap: break-word;
  word-break: break-all;
}

.bonus-wallet-wrap .wallet-inner-right .vip-next {
  float: right;
  text-align: right;
}

.pop-wrap td,
.pop-wrap th {
  padding: 6px;
  border: 1px solid rgba(51, 51, 51, 0.5);
}

.pop-wrap table {
  border-collapse: collapse;
}

.bg-pop {
  border-bottom: 1px solid #dfc98d;
  background: #c9a33d;
  word-break: break-word;
}

.turnover-message {
  text-align: center;
  font-family: 'Heebo-Medium';
  font-weight: bold;
  font-style: italic;
  font-size: 14px;
}
.mobile-to-message {
  padding: 15px 10px;
}

@media (max-width: 959px) {
  .history-card {
    font-size: 12px;
    height: 80%;
    overflow-x: hidden;
    overflow-y: hidden;

    .referral-table-header {
      color: #acacac;
    }
  }
}

@media (max-width: 757px) {
  .bonus-wallet-wrap {
    width: 100%;
  }

  .bonus-wallet-wrap .wallet-inner {
    padding-top: 20px;
  }

  .bonus-wallet-wrap .wallet-inner-left {
    width: 2.125rem;
    height: 2.125rem;
  }
}
</style>
